import { mapGetters } from 'vuex';

const { VUE_APP_ENABLE_AGENT_CODE } = process.env;

export default {
  name: 'TemplateSearchTab',
  props: {
    category: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: 'tabs',
    },
    agentList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      calendarData: 'GET_CALENDAR_DATA',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      marketerId: 'GET_MARKETER_ID',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      odyAgentCode: 'GET_SELECTED_ODYSSEY_AGENT_CODE',
      loginOdyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
      isFcAgentMarketerMode: 'GET_FC_AGENT_MARKETER_MODE',
    }),
    searchDisable() {
      return (
        !this.destination || !this.fromDate || !this.toDate || !this.countAdult
      );
    },
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult ? this.$store.getters.GET_SEARCH_CONTENT.adult : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child ? this.$store.getters.GET_SEARCH_CONTENT.child : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant ? this.$store.getters.GET_SEARCH_CONTENT.infant : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    availableDates() {
      const { calendarData } = this;
      if (!calendarData) return [];
      return [...this.calendarData];
    },
    destinationText() {
      const { destination, lang } = this;
      if (!destination) return '';

      const destinationList = this.category.destinations;
      const currentItem = destinationList.find((dest) => dest.code === destination);
      return `${currentItem.name[lang]}`;
    },
    disableDestination() {
      return this.isOdysseySite && (this.agent === '' || this.agent === null);
    },
    disableCalendar() {
      return this.destination === '';
    },
    disableOccupancy() {
      return this.fromDate === '' || this.toDate === '';
    },
    hotDestinations() {
      return this.category?.hotDestinations ?? [];
    },
  },
  watch: {
    category: 'changedCategory',
    lang() {},
    agent() {
      this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.agent);
    },
  },
  data() {
    return {
      categoryId: null,
      // availableDates: [],
      minDate: 'today',
      travelWork: false,
      isOrganized: false,

      destination: '',
      selectFamilyAll: 'all',
      fromDate: '',
      toDate: '',
      destinationChanged: false,
      dropdownShow: false,
      progressValue: 0,
      agent: '',
      changeEnableAgentCode: VUE_APP_ENABLE_AGENT_CODE,
    };
  },
  created() {
    this.agent = this.odyAgentCode;
  },
  async beforeMount() {
    // if (!this.calendarData) {
    //   await this.$store.dispatch('FETCH_CALENDAR_DATA');
    // }
    this.changedCategory();
  },
  methods: {
    submitSearch(event) {
      event.preventDefault();
      const categoryId = this.category.dealTypeCode;
      this.$store.dispatch('UPDATE_CURRENT_CATEGORY', { categoryId });
      if (this.isLoading) {
        // this.$bvModal.msgBoxConfirm(
        //   this.$t('search-tab.is-loading-warning-message'),
        //   {
        //     title: this.$t('search-tab.is-loading-warning-title'),
        //     size: 'sm',
        //     buttonSize: 'sm',
        //     okVariant: 'danger',
        //     okTitle: this.$t('login.yes'),
        //     cancelTitle: this.$t('login.no'),
        //     headerClass: 'p-2 border-bottom-0',
        //     footerClass: 'p-2 border-top-0',
        //     centered: true,
        //   },
        // )
        //   .then((value) => {
        //     if (value) {
        //       this.$store.dispatch('FETCH_STOP');
        //       this.applySearch();
        //     }
        //   });
        this.$store.dispatch('FETCH_STOP');
        setTimeout(() => { this.applySearch(); }, 100);

        return false;
      }
      this.applySearch();
      return true;
    },
    applySearch() {
      if (this.type === 'mobile') {
        this.$emit('closeSearchMobile');
      }

      if (this.searchDisable) {
        return false;
      }
      const body = {
          dest: this.destination,
          fromDate: this.fromDate,
          toDate: this.toDate,
          categoryId: this.category.code,
          adult: this.countAdult,
          child: this.countChild,
          infant: this.countInfant,
          odyAgentCode: this.agent,
        }, path = '/search-result';
      if (this.$route.query.utm_source) body.utm_source = this.$route.query.utm_source;
      if (this.$route.query.secret) body.secret = this.$route.query.secret; // temporary param for Ran's search
      if (this.isLanding) body.utm_source = this.landingInfo.utm_source;
      if (this.marketerId) body.marketerId = this.marketerId;
      if (this.isFcAgentMarketerMode) body['fc-agent-mode'] = '';
      const { query } = this.$route;
      if (Object.keys(body).every((key) => String(body[key]) === String(query[key]))) return false;

      this.$router.push({ path, query: body });
      if (this.isLanding) {
        const queryString = `${path}?${new URLSearchParams(body).toString()}`;
        window.location.href = queryString;
      }
      return true;
    },
    resetDate() {
      this.$store.dispatch('SET_SEARCH_ITEM', ['from', '']);
      this.$store.dispatch('SET_SEARCH_ITEM', ['to', '']);
    },
    optionSelect(value) {
      if (this.device === 'desktop') {
        this.destinationChanged = value;
      }
    },
    async changed(dest) {
      if (!dest || (Array.isArray(dest) && !dest[0])) return false;
      this.resetDate();
      this.destination = dest.length ? dest[0].value : dest.value;

      const categorySubjectIdList = this.category.subjectCodes.map(
        (item) => item,
      );

      await this.$store.dispatch('FETCH_CALENDAR_DATA', { dest: this.destination, subjects: categorySubjectIdList });

      // if (this.calendarData) {
      //   this.availableDates = this.calendarData.filter(
      //     (rec) => rec.dest === this.destination && categorySubjectIdList.includes(rec.sub),
      //   );
      // }
      return true;
    },
    chooseDate(date) {
      this.fromDate = date.from;
      this.toDate = date.to;
    },
    finalDateSelect(value) {
      this.dropdownShow = value;
    },
    changeCount({ adult, child, infant }) {
      this.countAdult = adult;
      this.countChild = child;
      this.countInfant = infant;
    },
    changedCategory() {
      this.categoryId = this.category.code;
      this.isOrganized = this.category.code === 'Organize_tour_packages';
    },
  },
};
